<template>
	<v-layout row wrap class="giving-forms-intro-wrapper">
		<v-flex md6 class="pa-4">
			<r-header>Let’s introduce you to giving forms.</r-header>
			<p class="subheading mt-4">
				A giving form is the webpage you create for your contributors to give online. You can create as many giving forms as you want, and customize them individually to include different funds, payment options, color schemes, and information.
			</p>
			<p class="subheading mt-4">
				If you already have giving links set up on your website, you will need to update them with the link to the corresponding giving form you create. Because a default giving form is always assigned, outdated links will automatically point to that form.
			</p>
			<div class="text-center mt-5">
				<div v-if="!isChmsGivingIntegration" class="responsive-iframe"><iframe src="https://player.vimeo.com/video/389322834" frameborder="0"></iframe></div>
				<div v-else class="responsive-iframe"><iframe src="https://player.vimeo.com/video/967187434" frameborder="0"></iframe></div>
			</div>
			<div class="text-center mt-4">
				<r-btn
					v-if="fundsLoaded && settingsLoaded && fundState.onlineFunds.length > 0 && givingState.settings && givingState.settings.givingDepositAccounts.length > 0 && givingFormsState.givingForms.length === 0"
					:to="{ name: 'giving-form', params: { page: 'general-settings' }, query: { isNew: true } }"
					large
				>
					<v-icon>add</v-icon>
					&nbsp;Create Your First Giving Form
				</r-btn>

				<r-btn v-else-if="fundsLoaded && settingsLoaded && fundState.onlineFunds.length > 0 && givingState.settings && givingState.settings.givingDepositAccounts.length > 0 && givingFormsState.givingForms.length > 0" :to="{ name: 'giving-forms', params: {}, query: { showIntro: false } }" large>
					Get Started
				</r-btn>

				<r-btn v-else-if="fundsLoaded && settingsLoaded" target="_parent" href="Giving/OnlineGiving" large>
					Get Started
				</r-btn>
			</div>
		</v-flex>
		<v-flex md6>
			<img class="responsive-image round-corners" src="/Assets/SPA/images/giving/giving_form_example.jpg" />
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {
			fundsLoaded: false,
			settingsLoaded: false,
			givingState: _REALM.STORE.givingStore.state,
			fundState: _REALM.STORE.fundStore.state,
			givingFormsState: _REALM.STORE.givingFormStore.state,
            isChmsGivingIntegration: _REALM.STORE.siteStore.isChmsGivingIntegration()
		};
	},
	created() {
		this.onlineGivingReady();
	},
	methods: {
		onlineGivingReady() {
			var self = this;
			_REALM.STORE.fundStore.listOnline({ filterCampusFunds: false }).then(function() {
				self.fundsLoaded = true;
			});

			_REALM.STORE.givingStore.settings().then(function() {
				self.settingsLoaded = true;
			});
		}
	}
};
</script>
