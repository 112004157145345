<template>
	<v-layout row wrap>
		<v-flex v-if="givingFormsLoaded && !showIntroPage" xs12>
			<r-card class="mt-2">
				<v-layout row wrap>
					<v-flex xs12>
						<GivingFormsGrid></GivingFormsGrid>
					</v-flex>
				</v-layout>
			</r-card>
		</v-flex>

		<GivingFormsIntro v-if="givingFormsLoaded && showIntroPage"></GivingFormsIntro>
	</v-layout>
</template>

<script>
import GivingFormsGrid from '@/components/giving/givingForms/GivingFormsGrid.vue';
import GivingFormsIntro from '@/components/giving/givingForms/GivingFormsIntro.vue';
import moment from 'moment';

export default {
	components: {
		GivingFormsGrid,
		GivingFormsIntro
	},
	data() {
		return {
			givingFormsLoaded: false,
			fundsLoaded: false,
			givingFormsState: _REALM.STORE.givingFormStore.state,
			fundState: _REALM.STORE.fundStore.state
		};
	},
	computed: {
		showIntroPage() {
			if ((this.givingFormsLoaded && this.givingFormsState.givingForms.length === 0) || (this.fundsLoaded && this.fundState.onlineFunds.length === 0)) {
				return true;
			}

			if (this.givingFormsLoaded && this.givingFormsState.givingForms.length > 0 && (_REALM.VM.$route.query.showIntro === false || _REALM.STORAGE.get('givingFormsIntroDismissed') === true || moment.utc().diff(this.givingFormsState.givingForms[0].dateCreated, 'days') > 7)) {
				return false;
			}

			return true;
		}
	},
	created() {
		this.onlineGivingReady();
	},
	methods: {
		async onlineGivingReady() {
			const self = this;
			const promises = [_REALM.STORE.givingFormStore.listGivingForms(_REALM.STORE.siteStore.state.site.siteId), _REALM.STORE.fundStore.listOnline({ filterCampusFunds: false }), _REALM.STORE.memorialStore.listActive(), _REALM.STORE.givingStore.settings()];

			await Promise.all(promises).then(() => {
				self.fundsLoaded = true;
				self.givingFormsLoaded = true;
			});
		}
	}
};
</script>
