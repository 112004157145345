<style lang="scss">
@import '@/scss/variables.scss';
.giving-forms-grid-wrapper {
	.active-inactive-wrapper {
		max-width: 200px;
		.v-btn.v-item--active.v-btn--active {
			border: $realm-blue 1px solid !important;
		}
	}
}
</style>
<template>
	<v-flex xs12 class="giving-forms-grid-wrapper">
		<v-layout>
			<r-btn :to="{ name: 'giving-form', params: { page: 'general-settings' }, query: { isNew: true } }">
				<v-icon>add</v-icon>
				&nbsp;Add Giving Form
			</r-btn>
			<v-spacer></v-spacer>

			<v-flex v-if="deactivatedGivingForms.length > 0" text-right>
				<v-btn-toggle v-model="showActiveGivingForms" class="active-inactive-wrapper" mandatory dense>
					<v-btn :value="true">
						Active
					</v-btn>
					<v-btn :value="false">
						Inactive
					</v-btn>
				</v-btn-toggle>
			</v-flex>
		</v-layout>

		<r-table :headers="headers" :items="filteredGivingForms" :init-sort="{ descending: false, sortBy: 'name' }">
			<template slot-scope="{ items }">
				<tr v-for="item in items" :key="item.formId">
					<td>
						<r-media-image :image-id="item.formId" :width="32" :image-type="imageTypes.GIVING_FORM" :border-radius="givingFormStore.getLogoBorderRadius(item.formId)" />
					</td>
					<td class="min-width">
						<router-link :to="{ name: 'giving-form', params: { page: 'general-use', formId: item.formId } }">{{ item.name }}</router-link>
					</td>
					<td>
						<r-chip v-if="item.isDefault" class="ml-1" small>Default</r-chip>
						<r-chip v-if="givingState.settings.isTextGivingEnabled && item.isText" class="ml-1" small>Text</r-chip>
						<r-chip v-if="item.isMobile" class="ml-1" small>Mobile</r-chip>
						<r-chip v-if="isNewForm(item)" class="ml-1" small color="success">New</r-chip>
					</td>

					<td>
						<div>{{ item.views | placeHolder }}</div>
					</td>
					<!--<td>
						{{ item.giftsCount | placeHolder }}
					</td>
					<td>
						<r-date-time :value="item.mostRecentGiftTime"></r-date-time>
					</td>
					<td class="text-right">
						{{ item.giftsAmount | centsToDollars }}
					</td>-->
					<td class="text-right">
						<r-actions :item="item">
							<r-action-item v-if="!item.dateDeactivated" :to="{ name: 'giving-form', params: { page: 'general-use', formId: item.formId } }">Edit</r-action-item>
							<r-action-item v-if="!item.dateDeactivated && !item.isDefault" @click="setDefaultGivingForm(item)">Make Default</r-action-item>
							<r-action-item v-if="item.dateDeactivated && !item.isDefault" @click="activateGivingForm(item)">Activate</r-action-item>
							<r-action-item v-if="givingFormState.givingForms.length > 1 && !item.dateDeactivated && !item.isDefault" @click="showDeactivateDialog(item, 'deactivate')">Deactivate</r-action-item>
							<r-action-item v-if="!item.dateDeactivated" @click="showDuplicateDialog(item)">Copy</r-action-item>
						</r-actions>
					</td>
				</tr>
			</template>
		</r-table>

		<r-dialog v-model="deactivateDialogVisible" max-width="400px" type="warning">
			<template slot="title">
				Are you sure?
			</template>
			<v-layout wrap>
				<v-flex xs12>
					<p v-if="givingFormState.givingForm.isDefault">Before continuing, you need to select a new default giving form to replace all instances of this form currently in use.</p>
					<p v-else>Your default giving form will replace all instances of this form that are still in use.</p>
				</v-flex>
			</v-layout>
			<template slot="actions">
				<v-spacer></v-spacer>
				<r-btn color="default" @click="deactivateDialogVisible = false">Cancel</r-btn>
				<r-btn color="warning" @click="deactivateGivingForm">Deactivate</r-btn>
			</template>
		</r-dialog>

		<r-dialog v-model="duplicateDialogVisible" max-width="600px" persistent type="primary">
			<template slot="title">
				Copy Giving Form
			</template>
			<v-layout wrap>
				<v-flex xs12>
					<OptionsForm ref="optionsFormRef"></OptionsForm>
				</v-flex>
			</v-layout>
			<template slot="actions">
				<v-spacer></v-spacer>
				<r-btn color="default" @click="closeDuplicateDialog">Cancel</r-btn>
				<r-btn @click="duplicateGivingForm">Copy</r-btn>
			</template>
		</r-dialog>
	</v-flex>
</template>

<script>
import moment from 'moment';
import OptionsForm from '@/components/giving/givingForms/OptionsForm.vue';
const headers = [
	{ text: '', value: 'img', sortable: false, width: '32px' },
	{ text: 'Name', align: 'left', value: 'name' },
	{ text: '', align: 'left', value: '' },
	{ text: 'Views', align: 'left', value: 'views' },
	//{ text: 'Gifts', align: 'left', value: 'giftsCount' },
	//{ text: 'Last Gift', align: 'left', value: 'mostRecentGiftTime' },
	//{ text: 'Total', align: 'right', value: 'giftsAmount' },
	{ text: '', value: 'actions', sortable: false }
];

export default {
	components: {
		OptionsForm
	},
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			givingFormStore: _REALM.STORE.givingFormStore,
			givingState: _REALM.STORE.givingStore.state,
			accountState: _REALM.STORE.accountStore.state,
			deactivateDialogVisible: false,
			duplicateDialogVisible: false,
			headers: headers,
			imageTypes: _REALM.STORE.mediaStore.ImageTypes,
			givingFormBeingCopied: null,
			showActiveGivingForms: true
		};
	},
	computed: {
		filteredGivingForms() {
			var self = this;
			return self.givingFormState.givingForms.filter(function(givingForm) {
				if ((!self.showActiveGivingForms && givingForm.dateDeactivated) || (self.showActiveGivingForms && !givingForm.dateDeactivated)) {
					return true;
				}
			});
		},
		deactivatedGivingForms() {
			return this.givingFormState.givingForms.filter(function(givingForm) {
				return givingForm.dateDeactivated;
			});
		}
	},
	watch: {
		duplicateDialogVisible(val) {
			if (!val) {
				_REALM.STORE.givingFormStore.clearCurrentGivingForm();
			}
		}
	},
	created() {
		_REALM.STORE.givingFormStore.clearCurrentGivingForm();
		_REALM.STORAGE.set('givingFormsIntroDismissed', true);
	},
	methods: {
		isNewForm(givingForm) {
			return moment.utc().diff(givingForm.dateCreated, 'minutes') < 30;
		},
		showDeactivateDialog(givingForm) {
			_REALM.STORE.givingFormStore.setCurrentGivingForm(givingForm);
			this.deactivateDialogVisible = true;
		},
		closeDuplicateDialog() {
			this.duplicateDialogVisible = false;
		},
		showDuplicateDialog(givingForm) {
			this.givingFormBeingCopied = givingForm;

			var clonedGivingForm = {
				buttonColor: givingForm.buttonColor,
				defaultAmount: givingForm.defaultAmount,
				font: givingForm.font,
				funds: givingForm.funds,
				headerBackgroundColor: givingForm.headerBackgroundColor,
				introMessage: givingForm.introMessage,
				receiptMessage: givingForm.receiptMessage,
				siteId: givingForm.siteId,
				subtitle: givingForm.subtitle,
				title: givingForm.title,
				useDarkBodyScheme: givingForm.useDarkBodyScheme,
				useDarkOverlayScheme: givingForm.useDarkOverlayScheme,
				memorials: givingForm.memorials
			};

			if (typeof givingForm.logoBorderRadius !== 'undefined') {
				clonedGivingForm.logoBorderRadius = givingForm.logoBorderRadius;
			}

			_REALM.STORE.givingFormStore.setCurrentGivingForm(clonedGivingForm);
			this.duplicateDialogVisible = true;
		},
		deactivateGivingForm() {
			var self = this;
			_REALM.STORE.givingFormStore.toggleActiveGivingForm(_REALM.STORE.givingFormStore.state.givingForm.formId).then(function() {
				_REALM.TOAST.success(`${self.givingFormState.givingForm.name} has been deactivated.`);
				_REALM.STORE.givingFormStore.clearCurrentGivingForm();
				self.deactivateDialogVisible = false;
				self.showActiveGivingForms = false;
			});
		},
		duplicateGivingForm() {
			var self = this;
			_REALM.LOADER.show();
			return _REALM.STORE.givingFormStore.addGivingForm(this.givingFormState.givingForm).then(function(givingForm) {
				var originalGivingFormImageUrl = _REALM.STORE.mediaStore.getImageURL(_REALM.STORE.mediaStore.ImageTypes.GIVING_FORM, self.givingFormBeingCopied.formId, 200, 200);
				fetch(originalGivingFormImageUrl)
					.then(function(response) {
						return response.status == 200 ? response.blob() : null;
					})
					.then(function(imageBlob) {
						if (imageBlob) {
							_REALM.STORE.mediaStore.uploadImage(givingForm.formId, _REALM.STORE.mediaStore.ImageTypes.GIVING_FORM, imageBlob).then(function() {
								_REALM.EVENT.emit(givingForm.formId + '-refresh');
								self.afterDuplicateGivingForm();
							});
						} else {
							self.afterDuplicateGivingForm();
						}
					});
			});
		},
		afterDuplicateGivingForm() {
			_REALM.TOAST.success('Giving form has been copied.');
			this.closeDuplicateDialog();
			_REALM.LOADER.hide();
		},
		activateGivingForm(givingForm) {
			var self = this;
			_REALM.STORE.givingFormStore.toggleActiveGivingForm(givingForm.formId).then(function() {
				_REALM.TOAST.success(`${givingForm.name} has been activated.`);
				self.showActiveGivingForms = true;
			});
		},
		setDefaultGivingForm(givingForm) {
			givingForm.isDefault = true;
			_REALM.STORE.givingFormStore.updateGivingForm(givingForm).then(function() {
				_REALM.TOAST.success(`${givingForm.name} has been set as your default giving form.`);
			});
		}
	}
};
</script>
