<template>
	<r-form v-if="givingFormsLoaded" ref="optionsForm">
		<v-layout row wrap>
			<v-flex sm12 :sm9="isGeneralSettingsPage">
				<r-text-input
					v-model="givingFormState.givingForm.name"
					:loading="isUniqueValue.nameLoading"
					:error-messages="nameMessage.error"
					:success-messages="nameMessage.success"
					:maxlength="50"
					disableAutocorrect
					label="Giving Form Name"
					hint="For your organizational use only"
					persistent-hint
					required
					counter
					autofocus
				></r-text-input>
			</v-flex>
			<v-flex v-if="isGeneralSettingsPage" sm3>
				<r-text-input v-model="givingFormState.givingForm.defaultAmount" :min-value="300" type="atm" label="Suggested Gift" prefix="$" hint="Optional" persistent-hint></r-text-input>
			</v-flex>
			<v-flex sm12 :sm6="isGeneralSettingsPage">
				<r-text-input
					v-model="givingFormState.givingForm.urlPath"
					:prefix="givingFormStore.getGivingFormBaseUrl()"
					:loading="isUniqueValue.urlPathLoading"
					:error-messages="urlMessage.error"
					:success-messages="urlMessage.success"
					:minlength="1"
					:maxlength="20"
					pattern="[A-Za-z0-9-]"
					disableAutocorrect
					label="Unique URL"
					hint="Unique URL"
					persistent-hint
					counter
				></r-text-input>
			</v-flex>
			<v-flex v-if="givingState.settings.isTextGivingEnabled" sm12 :sm6="isGeneralSettingsPage">
				<r-text-input v-model="givingFormState.givingForm.textKeyword" pattern="[A-Za-z0-9-]" autocomplete="none" :disabled="givingFormState.givingForm.isText" :maxlength="20" label="Giving Form Text Keyword" :hint="textKeywordHint" persistent-hint counter></r-text-input>
			</v-flex>
			<v-flex v-if="isGeneralSettingsPage" xs12>
				<v-switch v-if="!givingFormState.givingForm._isDefaultOriginValue && givingFormState.givingForms.length > 0" v-model="givingFormState.givingForm.isDefault" label="Use this as my default giving form"></v-switch>
				<v-switch v-if="givingState.settings.isTextGivingEnabled && !givingFormState.givingForm._isTextOriginValue && givingFormState.givingForms.length > 0" v-model="givingFormState.givingForm.isText" label="Use this as my default text giving form"></v-switch>
				<v-switch v-if="memorialStore.siteHasMemorials()" v-model="memorialStore.state.editingSortableMemorials" label="Allow contributors to dedicate a gift"></v-switch>
				<!--<v-switch v-model="givingFormState.givingForm.isMobile" label="Use this as my default mobile giving form"></v-switch>-->
			</v-flex>
		</v-layout>
	</r-form>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
	data() {
		return {
			givingState: _REALM.STORE.givingStore.state,
			givingFormState: _REALM.STORE.givingFormStore.state,
			givingFormStore: _REALM.STORE.givingFormStore,
			memorialStore: _REALM.STORE.memorialStore,
			editFormId: _REALM.VM.$route.params.formId,
			isUnique: {
				name: null,
				nameLoading: false,
				urlPath: null,
				urlPathLoading: false
			},
			givingFormsLoaded: false
		};
	},
	computed: {
		nameMessage() {
			return {
				error: this.isUnique.name === false ? 'Not Avaliable' : [],
				success: this.isUnique.name === true ? 'Available' : []
			};
		},
		urlMessage() {
			return {
				error: this.isUnique.urlPath === false ? 'Not Avaliable' : [],
				success: this.isUnique.urlPath === true ? 'Available' : []
			};
		},
		textKeywordHint() {
			if (!this.givingFormState.givingForm.isText && this.givingFormState.givingForm.textKeyword) {
				return 'Text "' + _REALM.STORE.givingStore.state.settings.primaryKeyword + ' ' + this.givingFormState.givingForm.textKeyword + '" to use this giving form.';
			} else if (this.givingFormState.givingForm.isText) {
				return 'Text "' + _REALM.STORE.givingStore.state.settings.primaryKeyword + '" to use this giving form.';
			}

			return 'Optional';
		},
		isGeneralSettingsPage() {
			return _REALM.VM.$route.params.page === 'general-settings';
		}
	},
	watch: {
		'givingFormState.givingForm.name'() {
			this.debouncedIsUnique('name');
		},
		'givingFormState.givingForm.urlPath'() {
			this.debouncedIsUnique('urlPath');
		},
		'givingFormState.givingForm.isText'(val) {
			this.givingFormState.givingForm.textKeyword = val ? _REALM.STORE.givingStore.state.settings.primaryKeyword : null;
		}
	},
	created() {
		this.getGivingForms();
		this.debouncedIsUnique = debounce(this.verifyUniqueGivingForm, 500);
	},
	methods: {
		setGivingFormDefaults() {
			if (this.givingFormState.givingForms.length === 0) {
				this.givingFormState.givingForm.isDefault = true;
				this.givingFormState.givingForm.isText = true;
				this.givingFormState.givingForm.name = 'My First Giving Form';
				this.givingFormState.givingForm.urlPath = 'now';
			}

			if (this.givingFormState.givingForm.isText) {
				this.givingFormState.givingForm.textKeyword = _REALM.STORE.givingStore.state.settings.primaryKeyword;
			}
		},
		getGivingForms() {
			var self = this;
			return _REALM.STORE.givingFormStore.listGivingForms(_REALM.STORE.siteStore.state.site.siteId).then(function() {
				self.givingFormsLoaded = true;
				self.setGivingFormDefaults();
			});
		},
		verifyUniqueGivingForm(property) {
			return this.isUniqueValue(property);
		},
		isUniqueValue(property) {
			if (this.givingFormState.givingForm[property]) {
				let currentGivingForms = _REALM.STORE.givingFormStore.state.givingForms;
				let newValue = this.givingFormState.givingForm[property].toLowerCase();

				this.isUnique[property + 'Loading'] = true;

				for (var i = 0; i < currentGivingForms.length; i++) {
					if (currentGivingForms[i][property].toLowerCase() === newValue && this.editFormId !== currentGivingForms[i].formId) {
						this.isUnique[property + 'Loading'] = false;
						this.isUnique[property] = false;
						return;
					}
				}

				this.isUnique[property + 'Loading'] = true;
				this.isUnique[property] = true;
			}
		},
		validateForm() {
			return this.$refs.optionsForm.validate();
		}
	}
};
</script>
